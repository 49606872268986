<template>
  <div class="w-100 transition">
    <GenericReport
      ref="genericReport"
      title="Relatório Esterilização / Desinfecção"
      :route="route"
      :fields="fields"
      :topFilters="topFilters"
      tipoRelatorio="relatorioEsterilizacao"
      enableLinkTo
      pathLinkTo="relatorioEsterilizacaoEquipamento"
    />
  </div>
</template>

<script>
import moment from 'moment';
import GenericReport from '@/components/Table/GenericReport';

export default {
  name: 'relatorioEsterilizacao',
  components: {
    GenericReport,
  },
  data() {
    let id_unidade = null;
    let initDate = moment().subtract(7, 'days');
    let endDate = moment();
    let id_tipo_esterilizacao = null;
    let ciclo_urgencia = null;
    if (this.$store.state.filtersStored && this.$store.state.filtersStored.relatorioEquipamentoEsterilizacao) {
      id_unidade = this.$store.state.filtersStored.relatorioEquipamentoEsterilizacao.id_unidade;
      initDate = this.$store.state.filtersStored.relatorioEquipamentoEsterilizacao.initDate;
      endDate = this.$store.state.filtersStored.relatorioEquipamentoEsterilizacao.endDate;
      id_tipo_esterilizacao = this.$store.state.filtersStored.relatorioEquipamentoEsterilizacao.id_tipo_esterilizacao;
      ciclo_urgencia = this.$store.state.filtersStored.relatorioEquipamentoEsterilizacao.ciclo_urgencia;
    }
    return {
      items: [],
      fields: [
        {
          key: 'processamento',
          label: 'Processamento',
          formatter: (value) => value.nome,
        },
        {
          key: 'unidade',
          label: 'Unidade',
          formatter: (value) => value.nome,
        },
        {
          key: 'equipamento',
          label: 'Equipamento',
          formatterSlot: (value) => value.nome,
        },
        {
          key: 'nCiclos',
          label: 'Nº de Ciclos',
        },
        {
          key: 'producao',
          label: 'Produção',
          formatter: (value) => `${value} pacotes`,
        },
      ],
      loading: false,
      errMsg: '',
      route: 'report/esterilizacao',
      visibility: {
        date: false,
      },
      topFilters: {
        id_unidade: {
          type: 'genericSelect',
          route: 'unidade',
          defaultValue: id_unidade,
          firstOption: {
            value: null,
            label: 'Unidade',
          },
        },
        date: {
          type: 'rangeDate',
          defaultInitDate: initDate,
          defaultEndDate: endDate,
        },
        id_tipo_esterilizacao: {
          type: 'genericSelect',
          route: 'tipoEsterilizacao',
          defaultValue: id_tipo_esterilizacao,
          firstOption: {
            value: null,
            label: 'Processamento',
          },
        },
        id_equipamento: {
          type: 'select',
          defaultValue: null,
          firstOption: {
            value: null,
            label: 'Equipamento',
          },
        },
        ciclo_urgencia: {
          type: 'select',
          defaultValue: ciclo_urgencia,
          firstOption: {
            value: null,
            label: 'Ciclo de Urgência',
          },
          options: [
            {
              value: 'true',
              label: 'Sim',
              text: 'Sim',
            },
            {
              value: 'false',
              label: 'Não',
              text: 'Não',
            },
          ],
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
$primary-color-50: #209f85;

.paginador {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.transition {
  transition: 0.3s ease-in-out;
}

#report h2 {
  margin-bottom: 30px;
}

#report table {
  background-color: #FFF;
}

#report table thead th {
  color: #7E829F;
  padding-bottom: 50px;
  font-weight: 900;
}

</style>
